<template>
  <div class="step--wrapper">
    <div class="step--content">
      <div
        :class="{
          'step-item': true,
          'step-item-active': step === item.step,
          'step-item-success': item.step < step || status === 'success',
          'step-item-error': item.step === step && status === 'error',
        }"
        v-for="(item, index) in stepList"
        :key="index"
      >
        <div class="step-item-number">
          <a-icon type="check" v-if="item.step < step || status === 'success'" />
          <a-icon type="close" v-if="item.step === step && status === 'error'" />
          <span v-if="item.step >= step && !status ">{{ index + 1 }}</span>
        </div>
        <div class="step-item-name">{{ item.name }}</div>
        <div class="step-item-icon" v-if="item.step + 1 !== stepList.length">
          <a-icon type="right" />
        </div>
      </div>
    </div>
    <div class="step--tips">{{ tips }}</div>
    <div class="split-line"></div>
  </div>
</template>

<script>
export default {
  name: 'Step',
  props: {
    stepList: {
      type: Array,
      default: () => []
    },
    step: {
      type: Number,
      default: 0
    },
    status: {
        type: String,
        default: 'success'
    }
  },
  computed: {
    tips () {
        return this.stepList[this.step]?.tips
    }
  }
}
</script>

<style lang="less">
.step--wrapper {
  width: 100%;
  font-size: 16px;
  color: #909399;
  .step--content {
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 4px 0px 0px 4px;
    background: rgba(245, 247, 250, 0.89);
    .step-item {
      display: flex;
      align-items: center;
      &-number,
      &-name {
        margin-right: 10px;
      }
      &-number {
        width: 24px;
        height: 24px;
        border: 2px solid #909399;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .step-item-active {
      color: #303133;
      .step-item-number {
        border-color: rgba(48, 49, 51, 1);
      }
    }
    .step-item-success {
      color: rgba(92, 184, 122, 1);
      .step-item-number {
        border-color: rgba(92, 184, 122, 1);
      }
    }
    .step-item-error{
        color: rgba(244, 68, 76, 1);
      .step-item-number {
        border-color: rgba(244, 68, 76, 1);
      }
    }
  }
  .step--tips{
    height: 20px;
    font-size: 14px;
    color: #6A6A6A;
    margin: 5px 0px;
  }
  .split-line {
    width: 100%;
    height: 0px;
    border: 1px solid #c4c4c4;
    opacity: 1;
  }
}
</style>
